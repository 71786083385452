var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('InfoBanner',{attrs:{"module_name":this.module_name}}),_c('PageTitle',{attrs:{"hide_btns":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Возвратные ЗИП ")]},proxy:true}])}),(_vm.info_message())?_c('InfoAlert',{staticClass:"mb-6",attrs:{"is_dark":""},scopedSlots:_vm._u([{key:"alert_text",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.info_message())}})]},proxy:true}],null,false,631939790)}):_vm._e(),_c('v-card',{class:_vm.$style.card},[_c('Filters',{class:_vm.$style.filters,on:{"filter_update":_vm.filter_update},scopedSlots:_vm._u([{key:"btn-outer",fn:function(){return [_c('v-btn',{class:[_vm.$style.btnOuter , 'mb-0'],attrs:{"text":""},on:{"click":function($event){_vm.cols_dialog = true}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-tune")]),_c('span',[_vm._v("Настройка полей")])],1)]},proxy:true}])}),_vm._l((_vm.filtered_cols),function(header,idx){return _c('ResizeCols',{key:idx,attrs:{"header":header,"storage_name":_vm.cols_storage_name}})}),_c('Table',{class:_vm.$style.cartsList,attrs:{"headers":_vm.filtered_cols,"items":_vm.items,"hide-default-footer":"","options":_vm.table_options,"show-select":""},on:{"update:options":function($event){_vm.table_options=$event},"toggle-select-all":_vm.select_all_toggle,"click:row":_vm.row_click_handler},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
var isSelected = ref.isSelected;
var select = ref.select;
return [(_vm.is_disable(item))?_c('v-icon',[_vm._v(" mdi-close ")]):_c('v-simple-checkbox',{attrs:{"ripple":false,"value":isSelected},on:{"input":function($event){return select($event)}}})]}},{key:"item.order_side_number",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('span',[_vm._v(" "+_vm._s(item.order_side_number)+" ")])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[(item.status)?_c('ReturnZipStatus',{attrs:{"status":item.status.slug}},[_vm._v(" "+_vm._s(item.status.name)+" ")]):_vm._e()],1)]}},{key:"item.nomenclature",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.product ? item.product.nomenclature : '')+" ")]}},{key:"item.product_code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.product ? item.product.product_code : '')+" ")]}},{key:"item.organization",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.organization)+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.date))+" ")]}},{key:"item.sent_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.sent_at))+" ")]}},{key:"item.replaced_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.replaced_at))+" ")]}},{key:"footer",fn:function(){return [_c('Pagination',{attrs:{"pagination_props":_vm.pagination_props},on:{"change_page":function($event){return _vm.upd_pagination('current_page', $event)},"page_size_changed":function($event){return _vm.upd_pagination('limit', $event)}}})]},proxy:true}],null,true),model:{value:(_vm.selected_items_model),callback:function ($$v) {_vm.selected_items_model=$$v},expression:"selected_items_model"}}),(!_vm.sending_dialog)?_c('Snackbar',{model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","small":"","color":"#fff","title":"Отменить"},on:{"click":_vm.clear_selected}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('span',[_vm._v(" Выбрано товаров: "+_vm._s(_vm.selected_items_model.length)+" ")]),_c('v-btn',{staticClass:"ml-auto",attrs:{"text":""},on:{"click":function($event){_vm.sending_dialog = true}}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-pencil-outline")]),_c('span',[_vm._v(" Сформировать отправку ")])],1)],1):_vm._e(),_c('SendingDialog',{attrs:{"items":_vm.selected_items_model},on:{"close":function($event){_vm.sending_dialog = false},"submit":_vm.clear_selected},model:{value:(_vm.sending_dialog),callback:function ($$v) {_vm.sending_dialog=$$v},expression:"sending_dialog"}}),_c('CustomColsDialog',{attrs:{"cols":_vm.cols,"cols_list":_vm.cols,"storage_name":_vm.cols_storage_name,"default_cols":_vm.cols_default},on:{"update:cols_list":function($event){_vm.cols=$event},"save_cols":function($event){_vm.cols_dialog = false},"cancel_dialog":function($event){_vm.cols_dialog = false},"close":function($event){_vm.cols_dialog = false}},model:{value:(_vm.cols_dialog),callback:function ($$v) {_vm.cols_dialog=$$v},expression:"cols_dialog"}})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }